/********************************************************************************************
This stylesheet is for the application template that will not change based on brand.
********************************************************************************************/

/********************
IMPORTS
********************/
@import "assets/scss/brand.scss";

@import 'assets/scss/base_material.scss';

// @import 'assets/scss/material.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: transparent;
}

.mat-form-field {
  min-height: 115px;
  min-width: 280px;
}

.zsiq_theme1 div.zsiq_cnt {
  display: none !important;
}

/********************
GLOBAL STYLES
********************/

.spinner-client-color {
  border-color: #4285f4;
}

.active .spinner-layer.spinner-client-color {
  opacity: 1;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

:focus {
  outline: none; // Just for now... Big no-no
  // outline: 2px solid var(--grey-dark);
}

body,
html {
  height: 100%;
  background: var(--grey-lite);

  @include breakpoint(xs) {
    background: white;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  @include breakpoint(xs) {
    padding: 0;
  }
}

p {
  margin-bottom: 0.25rem;
}

small,
.small {
  @include breakpoint(xs) {
    font-size: 70%;
  }
}

.full-width {
  max-width: 100% !important;
}

.container {
  width: 1440px;
  max-width: 100%;

  @include breakpoint(xs) {
    padding-left: 0;
    padding-right: 0;
  }
}

.container-fluid {
  padding: 0 !important;

  @include breakpoint(xl) {
    width: 100% !important;
  }
}

.container-margin {
  margin-top: 8.5em;
  margin-bottom: 4em;

  @include breakpoint(xs) {
    margin-top: 9.5em;
    margin-bottom: 2.5em;
  }
}

.row {
  margin: 0;
}


.mobile {
  display: none;

  @media (max-width: 767px) {
    display: block !important;
  }
}

.desktop {
  display: block;

  @media (max-width: 767px) {
    display: none !important;
  }
}

.mo-text {
  font-size: 0.5em;
  vertical-align: super;
}

div.coverage {
  position: relative;
  font-family: var(--font-family);
  font-weight: bold !important;
  color: var(--grey-dark);
  text-transform: uppercase;
  font-size: 14px !important;

  h3 {
    display: inline-block;
  }
}

:root .verify_form {
  label {
    overflow: visible;
  }
}

:root .modal-verify-vehicle {
  label {
    overflow: visible;
  }
}

:root .coverage-start-date {
  color: var(--primary-color-darker) !important;
}

:root .lowest-term-price {
  text-align: center;
  display: inline-block;
  position: relative;
  top: -18px;

  h3 {
    color: var(--primary-color-dark) !important;
    display: inline-block;
    font-size: 24px !important;
  }

  span {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    position: relative;
    top: -3px;
  }
}

:root .lowest-term-price-disclaimer {
  text-align: center;
  line-height: 18px;
  display: inline-block;
  position: relative;
  top: 10px;

  .excluding-taxes {
    display: block;
    font-size: 12px;
    color: var(--grey-dark);
  }
}

.modal-dialog-center {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.modal-dialog-centered {
  .modal-dialog {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

app-modal-technical-difficulty-error {
  .modal-content {
    border: 2px solid var(--primary-color-darker) !important;
    box-shadow: 0 0 20px var(--primary-color-darker) !important;

    h3 {
      color: var(--primary-color-darker);
    }
  }
}

i.fa-question-circle,
i.fa-info-circle {
  cursor: pointer;
}

// Captions
.cap-head,
.cap-head p {
  font-size: var(--f-cap-head-size) !important;
}

.cap-sub {
  font-size: var(--f-cap-sub-size);

  @include breakpoint(xxs) {
    font-size: var(--f-cap-sub-size-xxs);
  }
}

//lists
.list-group-item {
  border: 0;
  background: none;
  padding: 8px 0;
}

.bold {
  font-weight: 800 !important;
}

.uppercase {
  text-transform: uppercase;
}

.link {
  color: var(--primary-color-dark);
}

.link:hover {
  color: var(--primary-color-lite);
}

.blue-link {
  color: #1960d0 !important;
  font-size: 0.75em;
  font-weight: bold;
}

.blue-link:hover {
  color: #08419c !important;
}

a {
  color: #007bff;

  &:hover {
    color: #0056b3;
  }
}

.subfooter a {
  color: var(--grey-darkest);
  font-weight: var(--font-regular);
}

//Alerts
.alert {
  padding: 1em 2em 1em 3em;
  width: 100%;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 5px solid;
  @include shadow(2px 2px 10px 0 rgba(0, 0, 0, 0.3));
  font-weight: 500;

  mdb-icon {
    position: absolute;
    left: 15px;
    top: 21px;
  }
}

.alert-dismissible .close {
  padding: 1.2rem;
  color: var(--primary-color-dark);
  opacity: 1;
}

.alerts .alert-info {
  color: #096abc;
  border-color: #096abc;
  background: white;
  padding: 1rem 0.5rem 1rem 3rem;

}

.alert-warning {
  color: #ff6700;
  border-color: #ff6700;
  background: white;

  &.verify-alert {
    color: #ff6700;
    text-align: left;
    font-size: 11px;
    line-height: 19px;
    padding: 0px;
    border-left: none;
    box-shadow: none !important;
    padding-bottom: 15px;
  }
}

.alert-danger {
  color: #ca0b00;
  border-color: #ca0b00;
  background: white;
}

.alert-success {
  border-color: #4bb543;
  color: #4bb543;
  background: white;
}

mdb-error {
  line-height: 16px;
}

a.hidden-sr {
  padding: 8px;
  position: fixed;
  left: 50%;
  height: 45px;
  width: 325px;
  margin-left: -162px !important;
  transition: top 0.3s;
  z-index: 1000;
  line-height: 28px !important;
  background-color: #333333;
  color: white !important;
  top: -80px;
}

a.hidden-sr:focus {
  top: 80px;
  background-color: #333333;
}

a.hidden-sr:active,
a.hidden-sr:hover {
  background-color: #000000 !important;
  color: white;
}

select {
  height: 40px !important;
  background-color: white !important;
  padding-left: 7px;
  padding-right: 7px;
  color: black !important;
  border: 1px solid #000000 !important;
  border-radius: 0 !important;
}


#baseFocusLink {
  color: black;
}

.tooltip-left {
  padding: 0 20px !important;

  .arrow {
    right: 15px !important;
  }
}

.remaining-payments,
.payment-plan {
  color: var(--primary-color-darker);
}

/********************
Product Home Edit Vehicle Modal Auto Complete
********************/
.autocomplete-container {
  @include shadow(none);
}

.ng-autocomplete {
  width: 100% !important;
}

.global-search .autocomplete-container .input-container input {
  border: 1px solid #cccccc;
}

.where-vin {
  top: -15px;
  position: absolute;
  left: 30px;
}

/********************
NAVBAR STYLES
********************/
mdb-navbar {
  z-index: 100;

  nav {
    width: 100%;
  }
}

#mainContent {
  padding-top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  @include breakpoint(xs) {
    padding-top: 0;
  }
}

#mainContent.offset {

  padding-top: 55px;
  @include breakpoint(xs) {
    padding-top: 45px;
  }
}

app-navbar {
  position: absolute;
  width: 100%;
  display: block;
  z-index: 2;
}

.navbar .nav-dealer {
  padding: 0 0 0 10px;
  border-left: 1.0px solid #cccccc;
  margin-left: 10px;
  font-weight: 500;
  white-space: normal;
  font-size: 11px;
  line-height: 12px;
  display: flex;
  align-items: center;

  @include breakpoint(xs) {
    padding: 0 0 0 5px;
    // margin-left: 5px;
    font-size: 9.5px;
    line-height: 10.5px;
    // min-width: 90%;
  }
}

.navbar-brand {
  margin: 0;
}

navlinks ul li {
  margin-left: 50px;

  @include breakpoint(xs) {
    margin-left: 0px;
  }
}

.double-nav a {
  font-size: 1em !important;

  @include breakpoint(xs) {
    font-size: 1em !important;
  }
}

.navbar.scrolling-navbar {
  padding-top: 15px !important;
  padding-bottom: 15px !important;

  @include breakpoint(xs) {  
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.navbar.scrolling-navbar.top-nav-collapse {
  padding-top: 15px !important;
  padding-bottom: 15px !important;

  @include breakpoint(xs) {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.navbar .dropdown-toggle {
  border: none;
  color: inherit;
}

.navbar .myaccount {
  font-size: 1em;
  font-family: var(--font-family);
  font-weight: var(--font-regular);
}

.navbar .myaccount.show {
  font-size: 1em;
  font-family: var(--font-family);
  font-weight: var(--font-regular);
  color: var(--primary-color-dark) !important;
  border-bottom: var(--primary-color-dark) solid 3px;
}

.navbar .myaccount .dropdown-menu {
  // top: 25px;
  // left: -30px;
  // min-width: 13rem;
}

.dropdown .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:hover {
  background-color: var(--primary-color-dark) !important;
  background: var(--primary-color-dark) !important;
}

.navbar .nav-item .nav-link:hover {
  color: var(--primary-color-dark);
}

.dd-item {
  white-space: nowrap;
  padding: 25px;
  width: 100%;
  @include radius(3px);
}

.dd-item:hover {
  color: white !important;
  background: var(--primary-color-lite);
}

.dd-item .active {
  color: white !important;
  background: var(--primary-color-lite);
}

app-searchbox .md-form input {
  width: 300px;

  @include breakpoint(xs) {
    width: 100%;
  }
}

.demo-text {
  display: none;
  z-index: 100;
  position: fixed;
  top: 54px;
  width: 100%;
  background: #212121;
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 22px 12px 12px 12px;

  @include breakpoint(xs) {
    top: 60px;
  }
}

// Cart

.cart .image-parent img {
  height: 80px;
  width: 80px;
}

.cart .modal-header {
  padding-block-end: 15px !important;

  @include breakpoint(xs) {
    padding: 9px !important;
  }

  background-color: white !important;
  @include shadow(none);
  border-bottom: 1px solid var(--grey-lite) !important;
  border-top: 3px solid var(--primary-color-dark) !important;
}

.cart .modal-header h5,
.cart .modal-header i {
  color: var(--grey-darkest) !important;
}

.cart .modal-header h5 {
  margin-bottom: 0;
}

.cart .modal-footer {
  background-color: white !important;
  border-top: 1px solid var(--grey-lite);
}

.cart .modal .modal-side.modal-top-right {
  top: 0px !important;
  right: 0px !important;
}

.cart .modal-dialog {
  padding: 0 !important;
  top: 80px !important;
  right: 25px !important;

  @include breakpoint(xs) {
    width: 100%;
    top: 0 !important;
    right: 0 !important;
  }
}

.cart .modal-dialog .modal-content .modal-header {
  @include radius(0);
}

.cart .close:focus {
  outline: none;
}

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  bottom: 12px;
  right: 12px;
  // -webkit-transform: translateY(-50%);
  // -ms-transform: translateY(-50%);
  // transform: translateY(-50%);
  z-index: 10;
}

/* Style the icon bar links */
.icon-bar span,
.icon-bar a {
  display: block;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 60px;
  transition: all 0.3s ease;
  color: white !important;
  font-size: 1.5em;
  background-color: var(--primary-color-dark);
  @include radius(100px);
  margin-bottom: 4px;
  cursor: pointer;
}

.icon-bar .call {
  background-color: var(--primary-color-dark);
}

.icon-bar .chat {
  background-color: var(--primary-color-dark);
}

.icon-bar .chat:hover {
  background-color: var(--primary-color-lite);
  color: white;
}

.icon-bar .call:hover {
  background-color: var(--primary-color-lite);
  color: white;
}

/********************
SIDE NAV SECTION STYLES
********************/

.call.mobile {
  i {
    font-size: 0.83em;
  }

  @media screen and (max-width: 390px) {
    display: none !important;
  }
}

.navlinks-right li {
  margin-right: 40px;

  @include breakpoint(xs) {
    margin-right: 5px;
  }
}

.navbar {
  background: white;
  display: block;
}

.navbar .nav-item h3 {
  margin: 0 10px 0 0 !important;
  font-size: 22px !important;
  line-height: 20px !important;
}

.scroll {
  background: white;
}

.sidenavRight {
  height: 100%;
  width: 300px;
  margin-right: -300px;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 50px;
}


.sidenavLeft {
  height: 100%;
  width: 300px;
  margin-left: -300px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 50px;
}

header mdb-accordion .card {
  border-bottom: 1px solid var(--grey) !important;
}

.sidenav a,
.sidenav .accordion .card .card-header {
  padding: 15px;
  text-decoration: none;
  font-size: var(--f-body-size);
  font-weight: var(--f-body-weight);
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid #dadada;
}

.sidenav-item {
  display: block;
  width: 100%;

  a {
    display: block;
    width: 100%;
  }
}

.myaccount .accordion .card .card-header {
  padding: 15px;
  text-decoration: none;
  font-size: var(--f-body-size);
  font-weight: var(--f-body-weight);

  background-color: #333;
  display: block;
  transition: 0.3s;

  a h5 {
    color: #fafafa !important;
  }
}

.myaccount .md-accordion .card .card-body {
  background-color: #eeeeee;
}

header.md-accordion .card .card-header a {
  padding: 0;
  border: none;
}

header .accordion .card .card-header {
  border: none !important;
}

header .accordion mdb-accordion-item-head a {
  padding: 0;
  border: none;
}

header .accordion mdb-accordion-item-head a h5 {
  font-size: var(--f-body-size) !important;
  font-weight: var(--f-body-weight) !important;
  color: #333 !important;
}

header .accordion .active mdb-accordion-item-head {
  border-bottom: 2px solid var(--primary-color-dark);
}

.myaccount .accordion .active mdb-accordion-item-head {
  border-bottom: none;
}

mdb-accordion-item-head h5,
mdb-accordion-item-head h3 {
  color: var(--grey-darkest) !important;
  width: 95%;
}

header .accordion .active .card-header a {
  color: var(--primary-color-dark);
}

header .md-accordion .card .card-header a h5 {
  font-size: inherit;
  font-weight: inherit;
}

header .md-accordion .card:first-of-type,
.md-accordion .card:not(:first-of-type):not(:last-of-type) {
  border: none;
}

header .card-body {
  padding: 1rem !important;
}

header .card-body a {
  padding: 8px 0px 8px 8px;
  border: none;
}

header .accordion .card .card-body {
  padding-top: 0 !important;
}

.sidenav .closebtn {
  position: absolute;
  top: -5px;
  left: 10px;
  font-size: 32px;
  border: none;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

// Logo Brand
.sidenav .brand {
  width: 100%;
  float: left;
  padding: 0 75px 50px 75px;
}

.sidenav img {
  width: 100%;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/********************
HERO SECTION STYLES
********************/
.hero {
  position: relative;
  width: 100%;
  display: block;

  @include breakpoint(xs) {
    margin-bottom: 0 !important;
  }
}

.main-hero-bg {
  width: 100%;
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint(xs) {
    height: 30vh;
  }
}

.main-hero-section {
  margin-top: -10rem;

  @include breakpoint(xs) {
    margin-top: 0;
    padding: 0 !important;
  }

  .card {
    @include breakpoint(xs) {
      @include shadow(0 0 0 0);
    }
  }
}

.main-hero-section .card {
  min-width: 100px;
}

.main-hero-section mdb-card-title {
  padding: 0px 1rem;
}

.main-hero-section mdb-card-body {
  max-width: 80%;
  width: 1024px;
  margin: 0 auto;

  @include breakpoint(xs) {
    max-width: 95%;
  }
}

/********************
PLANS HERO SECTION STYLES
********************/
.plans-hero-bg {
  width: 100%;
  height: 35vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint(xl) {
    height: 50vh;
  }
}

.plans-hero-bg h1 {
  color: white !important;
  text-align: center;
  padding: 10px;
}

.plans-hero-section {
  margin-top: 0rem;

  // margin-top:-9rem;
  @include breakpoint(xs) {
    margin-top: 0rem;
    // margin-top:-5rem;
  }

  @include breakpoint(xxs) {
    margin-top: 0rem;
    // margin-top:-4rem;
  }
}

.plans-hero-section mdb-card-title {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.plans-hero-section mdb-card-body {
  padding: 0 1.5rem 1.5rem 1.5rem;
}

// Tabs and Pills
.plans-hero-section .md-pills .nav-link {
  width: 100%;
}

.plans-hero-section .tab-container .col-md-3,
.plans-hero-section .tab-container .col-md-9,
.plans-hero-section .tab-container ul li {
  padding: 0;
}

.plans-hero-section .tab-container .tab-pane ul li .nav-link {
  @include shadow(none);
  @include radiusTopLeft(30px);
  @include radiusBottomLeft(30px);
  @include radiusTopRight(0);
  @include radiusBottomRight(0);
  padding: 0.8em;
}

.plans-hero-section .tab-container .tab-pane .tab-content {
  @include shadow(none);
  @include radius(0);
}

.plans-hero-section .tab-container .tab-content {
  padding: 0;
}

.plans-hero-section .component-part {
  color: var(--grey-darker);
}

.plans-hero-section .md-tabs {
  margin: 0;
  padding: 0;
}

.plans-hero-section .card {
  @include radiusTopRight(0);
  @include radiusTopLeft(0);
}

.plans-hero-section .md-tabs .nav-item p {
  padding: 18px;
  font-size: 1.5em;
}

.plans-hero-section p {
  margin-bottom: 0;
}

.plans-hero-section .md-tabs .nav-item {
  border-right: 1px solid var(--grey-med);
  border-left: 1px solid var(--grey-med);
}

.plans-hero-section .md-tabs li:first-child {
  border-left: none !important;
}

.plans-hero-section .md-tabs li:last-child {
  border-right: none !important;
}

// MOBILE PLANS
.plans .md-tabs {
  display: block;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  background-color: var(--grey);
  @include radiusTopLeft(0px);
  @include radiusTopRight(0px);
  @include shadow(none);
}

.plans .md-tabs .nav-item {
  width: 33.33%;
  float: left;
  text-align: center;
  border-right: 1px solid var(--grey-dark);
}

.plans .md-tabs li:first-child:nth-last-child(1) {
  width: 100% !important;
}

.plans .md-tabs li:first-child:nth-last-child(2),
.plans .md-tabs li:first-child:nth-last-child(2)~li {
  width: 50% !important;
}

.plans .md-tabs li:first-child:nth-last-child(3),
.plans .md-tabs li:first-child:nth-last-child(3)~li {
  width: 33.33% !important;
}

.plans .md-tabs li:first-child {
  border-left: none !important;
}

.plans .md-tabs li:last-child {
  border-right: none !important;
}

.plans .md-tabs .nav-item a p {
  font-size: 1em;
  font-weight: bold;
  padding: 12px 4px;
}

.plans .md-tabs .nav-item a {
  color: var(--grey-dark);
}

.plans .md-tabs .nav-item.open .nav-link,
.plans .md-tabs .nav-link.active {
  background-color: var(--primary-color-dark);
  @include radiusTopLeft(0px);
  @include radiusTopRight(0px);
}

// Modal
.prices {
  padding: 1.25rem 0.25em;
  background: var(--grey-lite);
  color: #333333;
  @include radiusBottomRight(12px);
  @include radiusTopRight(12px);

  @include breakpoint(xs) {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    padding: 0.5em;
    @include radiusBottomRight(0px);
    @include radiusTopRight(0px);
  }

  @include breakpoint(xxs) {
    position: fixed;
    bottom: 0;
    z-index: 11;
    padding: 0.3em;
  }

  p {
    font-size: 1.05em !important;
    font-weight: 500;
  }

  .small {
    font-size: 0.7em;
  }

  hr {
    @include breakpoint(xs) {
      margin-top: 0.6em;
      margin-bottom: 0.6em;
    }
  }

  .title {
    border-bottom: 1px solid var(--secondary-color-med);
  }

  .payment {
    border-right: 1px solid var(--secondary-color-med);

    @include breakpoint(xs) {
      border-right: none;
    }
  }

  .mobile {
    font-size: 1em;
  }

  .terms {
    text-decoration: underline !important;
  }
}

.prices-modal .modal-header {
  @include breakpoint(xs) {
    padding: 2rem 1rem;
  }
}

.card-footer {
  background: none;
  border: none;
  padding: 15px;
}

// Accordion & Slick Styles

.plans .accordion .card .card-header {
  padding: 15px 8px;
}

.plans .accordion .card .card-header a h5 {
  color: var(--grey-darkest) !important;
  font-size: 1em;
}

.plans .accordion .card .card-header:hover {
  background: var(--grey-lite);
}

.plans .accordion .card .card-header:hover a h5 {
  color: var(--grey-darkest);
}

/********************
CHAT STYLES
********************/
.chat {
  .modal-header {
    padding: 0.6rem;
  }

  .modal-dialog.modal-notify.modal-info .modal-header {
    background-color: white;
    color: var(--grey-darkest);
    @include radius(50px);
  }

  .modal-dialog.modal-notify .modal-header {
    border-bottom: 1px solid var(--grey);
  }

  .modal-dialog.modal-notify .heading {
    color: var(--grey-darkest);
  }

  .modal-dialog.modal-notify.modal-info .fab,
  .modal-dialog.modal-notify.modal-info .far,
  .modal-dialog.modal-notify.modal-info .fas {
    color: var(--grey-darkest);
  }

  .modal-dialog.modal-notify .modal-body {
    padding: 0.75rem 0;
    background: var(--grey-lite);
  }

  .card .card-body .card-text {
    font-size: 0.9em !important;
    line-height: 1.3em !important;
  }

  .card-title {
    float: left;
    width: 100%;
  }

  .card-text:last-child {
    margin-bottom: 0 !important;
  }

  .modal-footer {
    padding-top: 0;
  }

  .modal-content:not(.card-image) .close {
    top: 18px;
  }

  .phone-modal {
    bottom: 0px !important;
    right: 60px !important;
  }

  .phone-modal .modal-content {
    @include radius(50px);
  }
}

#phoneModal {
  height: 0 !important;
}

/********************
PLANS HOME STYLES
********************/
.compare {

  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    padding-left: 0;
    padding-right: 0;
  }
}

app-slice-product .card-body {
  @include breakpoint(xs) {
    padding: 1rem 0 0 0;
  }
}

/********************
CUSTOMIZE PLAN SECTION STYLES
********************/

.addon-wrap .addon-button button.active,
ul.stepper.horizontal .step.active .step-title:before {
  background: var(--secondary-color-dark);
  border-color: var(--secondary-color-dark) !important;
  color: white !important;
}

.card-body ul.stepper.horizontal .step.active .step-title:before {
  background-color: var(--primary-color-dark) !important;
}

ul.stepper.horizontal {
  @include breakpoint(xs) {
    min-height: 100vh !important;
  }

  min-height: 75vh !important;
}

.btn-round {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;

  i {
    line-height: 1;
  }
}

.customize-plan-wrap {
  .card {
    @include breakpoint(xs) {
      @include shadow(none);
    }

    margin-bottom: 5em;
    @include radius(12px);

    .btn {
      position: absolute;
      bottom: 20px;
      width: 150px;
    }

    .btn-next {
      right: 35px;
      z-index: 1000;
    }

    .btn-back {
      left: 15px;
    }

    @include breakpoint(xs) {
      .btn {
        position: relative;
        width: 45%;
      }

      .btn-next {
        right: 0;
      }

      .btn-back {
        left: 0;
      }
    }
  }
}

.p-disclose {
  display: none;
}

.customize-bold {
  font-weight: bold !important;
}

mdb-stepper {
  max-height: 70vh;
  float: left;

  @include breakpoint(mh) {
    max-height: 100vh;
  }

  @include breakpoint(xs) {
    max-height: 100vh;
  }
}

.customize-plan input,
.customize-plan .md-form mdb-select .form-control {
  font-size: 1.25em;
  font-weight: bold;
  color: #333333;
}

.customize-plan-wrap ul.stepper .md-form label {
  top: -5px;
}

.md-form .prefix~input,
.md-form .prefix~textarea {
  margin-left: 1.2rem;
}

/********************
FULL-WIDTH 2-COLUMN LEFT AND RIGHT SECTION STYLES
********************/
.fw-2cr,
.fw-2cl {
  background: white;
  margin-top: 100px;
  width: 100%;
  float: left;
}

.fw-2cil {
  width: 50%;
  float: left;

  @include breakpoint(xs) {
    width: 100%;
    float: left;
    height: 250px;
  }
}

.fw-2cir {
  width: 50%;
  float: right;

  @include breakpoint(xs) {
    width: 100%;
    float: left;
    height: 250px;
  }
}

.fw-2ct {
  width: 50%;
  float: left;
  padding: 40px;

  @include breakpoint(xs) {
    width: 100%;
    float: left;
  }
}

/********************
HOW IT WORKS STYLES
********************/
.hiw {
  background: var(--grey-lite);
  margin-top: 100px;
  width: 100%;
  float: left;
}

.hiw-img {
  // max-width:150px;
  // padding:15px;
  margin: 0 auto;
}

/********************
FACT SLIDER SECTION
********************/
.facts {
  width: 100%;
  float: left;
}

.facts .slick-slide .card .card-body {
  padding: 1.75em;

  @include breakpoint(sm) {
    padding: 1em;
  }
}

.facts .card .card-body .card-text,
.facts .card .card-body p {
  font-size: 1.4em !important;
  line-height: 1.4em !important;
}

.fact-title {
  border-bottom: 1px solid #d3d3d3 !important;
}

.slick-slide .card .card-body .fact-title p {
  font-size: 1.5em;
  padding: 10px 0 15px 0;
  color: var(--primary-color-dark);
  font-weight: 500 !important;
  line-height: 1.25em !important;
}

.facts .card .card-title h2 {
  font-weight: 400 !important;
  color: #df6357 !important;
  font-size: 2em !important;
}

.facts .card {
  @include radius(0.1rem);
  min-height: 400px;
}

/********************
COMPARE SECTION
********************/
.compare ul li span h6 {
  padding-bottom: 8px;
  font-size: 0.9em;

  @include breakpoint(xs) {
    font-size: 0.75em;
  }
}

.compare ul li i {
  @include breakpoint(xs) {
    font-size: 0.75em;
  }
}

.compare ul li img {
  margin-right: 8px;
}

/****
CONTENT BLOCK
****/

.content-block-wrap {
  @include breakpoint(xs) {
    margin-top: 0 !important;
    margin-bottom: 0;
  }
}

.title-component,
.plans-title-section {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit !important;
    text-align: inherit;
  }
}

.content-feature {
  &.content-feature-top-padding {
    padding-top: 3rem !important;
  }

  @include breakpoint(xs) {
    padding: 0px !important;
    padding-top: 40px !important;

    &.content-feature-top-padding {
      padding-top: 0 !important;
    }
  }
}

.content-feature .mobile-margin {
  @include breakpoint(xs) {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 0 !important;
    // padding-bottom: 25px !important;
  }
}

.content-block,
.content-margin {

  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    padding: 0;
  }
}

app-component-image {
  width: 100%;
}

.feature-image,
.feature-image .full-img-container {
  width: 100%;
  float: left;
  height: 100%;
}

.feature-component-first {
  padding-top: 50px;
}

.feature-component-last {
  padding-bottom: 50px;
}

app-component-image .full-img {
  width: 100%;
  float: left;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: center;
  min-height: 30vh;
}

/********************
FEATURES SECTION
********************/
.features {
  width: 100%;
  float: left;
  padding-top: 100px;
  padding-bottom: 70px;
  background: var(--grey-darkest);
}

.features h1,
.features h2,
.features p,
.features h4,
.features .cap-head,
.features mdb-icon {
  color: white;
}

.features mdb-icon {
  font-size: 4em;
  padding: 20px;
}

.features .img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}


/********************
SLICK CAROUSEL STYLES
********************/
@media (min-width: 768px) {
  ngx-slick-carousel {
    min-height: 600px;
    height: 85vh;
    max-height: 700px;
  }
}

app-select-your-plan ngx-slick-carousel {
  /* This overrides the general styles specifically for ngx-slick-carousel inside app-select-your-plan */
  min-height: initial;
  height: auto;
  max-height: none;
}

.carousel-multi-item .controls-top {
  display: none;
}

.carousel-multi-item .carousel-indicators {
  margin-bottom: -1rem;
}

.carousel-multi-item {
  margin-bottom: 0;
}

.carousel-inner {
  overflow: visible !important;
}

.carousel-item {
  overflow: visible !important;
}

.slick-slide:focus {
  outline: none;
}

.slick-slide img {
  width: 100%;
}

.slick-slide .card .card-body {
  padding: 0.25rem;
  // @include breakpoint(xs){
  //     padding:1.25rem .25rem;
  // }
}

.slick-dots li button:before {
  font-size: 15px;
}

.slick-dotted.slick-slider {
  margin-bottom: 50px;
}

.slick-prev:before,
.slick-next:before {
  color: #333333;
  font-size: 32px;
}

.slick-prev,
.slick-next {
  height: 32px;
  width: 32px;
  top: 103%;
  z-index: 1;
}

.slick-prev {
  left: 43%;

  @include breakpoint(xs) {
    left: 4%;
  }
}

.slick-next {
  left: 55%;

  @include breakpoint(xs) {
    left: 89%;
  }
}

app-component-icon-group .slick-prev,
app-component-icon-group .slick-next {
  top: 110% !important;
}

app-component-icon-group .slick-dots {
  bottom: -30px !important;
}

/********************
MODAL POPUP STYLES
********************/

.modal {
  z-index: 1000050;
}

.modal-backdrop {
  z-index: 1000040;
}

.modal-dialog {
  max-width: 1024px;
  padding: 20px;
}

.modal-dialog-full {
  padding: 0px;
}

.modal-dialog-full .modal-content {
  padding: 0px;
  max-width: 100% !important;
}

.modal-dialog-full .modal-dialog .modal-content {
  box-shadow: none !important;
  border-radius: 0;
  border: 0;
}

.modal-dark .modal-content {
  background-color: transparent !important;

  @include breakpoint(xs) {
    background-color: white !important;
  }
}

.modal .modal-full-height.modal-bottom .modal-content {
  bottom: 0rem !important;
}

.term-statement {
  color: #606060;
  font-size: 12px;
  margin-top: -20px;
  line-height: 16px;
  padding-bottom: 15px;
  text-align: center;
  max-width: 750px;
  margin-right: auto;
  margin-left: auto;

  @include breakpoint(xs) {
    margin-top: -20px;
  }
}

.disclaimers {
  color: rgb(120, 124, 134);
}

.disclaimer.mobile {
  .close-big {
    color: #000;
    font-weight: 300;
    text-shadow: 0 1px 0 #fff;
    font-size: 3rem;
    background-color: transparent;
    border: none;
    margin-right: 10px;
  }
}

.modal-content.dark {
  background-color: rgba(0, 0, 0, 0.85) !important;
  color: rgb(255, 255, 255) !important;

  .close-big {
    color: #fff;
    font-weight: 300;
    text-shadow: 0 1px 0 #fff;
    font-size: 3rem;
    background-color: transparent;
    border: none;
    margin-right: 10px;
  }
}

.modal-dialog.medium {
  max-width: 500px;
}

.modal-header {
  border: none;
}

.modal-body h4 {
  @include breakpoint(xs) {
    margin-top: 10px;
  }
}

.modal-footer {
  border: none;
  border-radius: 0;
}

.sidenavRight .modal-close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  font-size: 1.25em;
  z-index: 1;
}

.sidenavLeft .modal-close-icon {
  position: absolute;
  top: 12px;
  left: 12px;
  cursor: pointer;
  font-size: 1.25em;
  z-index: 1;
}

.modal-back-btn {
  position: absolute;
  top: -10px;
  left: 20px;
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  z-index: 1;
  font-size: 1.1em;
  background: var(--primary-color-dark);
  color: white;
  border: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.modal-back-btn:hover {
  background: var(--primary-color-lite);
}

.modal-back-btn:focus {
  outline: none;
}

// .modal-body.modal-address {
//   padding-left: 50px;
//   padding-right: 50px;
// }

/********************
FORM STYLES
********************/
.md-form {
  margin: 0;
  margin-bottom: 25px;
  margin-top: 25px;

  @include breakpoint(xs) {
    // margin-bottom: 15px;
    margin-top: 15px;
  }

  label:not(.form-check-label) {
    z-index: 1;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.md-form mdb-select .form-control {
  z-index: 2;
}

/********************
TAB/PILL STYLES
********************/
.md-tabs .nav-item a {
  height: 100%;
}

.md-pills .nav-item a,
.md-pills .nav-item .active,
.md-pills .nav-item a .active {
  height: 100%;
}

.md-tabs a.waves-effect,
.md-tabs a.waves-light,
.md-pills a.waves-effect,
.md-pills a.waves-light {
  display: block;
}

// For Modal Popups
.modal .md-pills {
  -webkit-border-top-left-radius: 20px;
  -webkit-border-top-right-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-topright: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}

.modal .md-pills .nav-link.active {
  box-shadow: none !important;
  width: 100%;
}

.modal .md-pills .nav-link {
  width: 100%;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
}

.modal .md-pills li {
  padding: 0 !important;
}

.modal .tab-content {
  box-shadow: none !important;
}

.form-check.address-check {
  padding-left: 0px;
}

.address-verification {
  min-height: 300px;
}

/********************
FOOTER STYLES
********************/

.footer-links {
  flex-flow: row wrap;

  @include breakpoint(xs) {
    flex-flow: column wrap;
  }
}

footer {
  width: 100%;
  float: left;
  background: white;
  padding-top: 30px;
}

footer .nav-dealer {
  display: inline-block;
  padding: 5px 0 5px 10px;
  border-left: 1.0px solid #cccccc;
  margin-left: 10px;
  font-weight: 500;
  color: white;

  @include breakpoint(xs) {
    border-left: none;
    margin-left: 0;
    display: block;
    padding: 5px 0;
  }
}

footer ul li {
  padding-bottom: 8px;
}

footer ul mdb-icon {
  color: var(--primary-color-dark);
  margin-right: 12px;
}

footer .footer-copyright {
  width: 100%;
  float: left;
  background: var(--grey);
  margin-top: 25px;
  padding-bottom: 200px !important;
}

footer .footer-copyright {
  text-align: center;
}

footer .footer-copyright a {
  padding: 0 12px;
  cursor: pointer;
}

footer .rights-reserved {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

footer .footer-version {
  font-size: 12px;
  color: white;
}


.subfooter {
  color: black;
  background: white;

  .subfooter-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      padding: 5px;
      margin-right: 10px;
    }
  }
}

.footer-demo-text {
  font-size: 1rem !important;
}

/********************
LOADING STYLES
********************/

.loading-modal {
  min-height: 250px;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;

  p {
    width: 6rem;
    height: 3rem;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    color: rgb(37, 37, 37) !important;
  }

  .indicator {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    margin-left: -1.5rem;
    margin-top: -3.5rem;
    left: 50%;
  }
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;

  p {
    width: 6rem;
    height: 3rem;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    color: rgb(37, 37, 37) !important;
  }

  .indicator {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    margin-left: -1.5rem;
    margin-top: -3.5rem;
    left: 50%;
  }
}

// disclaimer
footer .disclaimer {
  margin-bottom: 25px;
  border-bottom: 1px solid var(--grey-med);
}

.disclaimer p,
.disclaimer ol,
.disclaimer ul,
.disclaimer .list-group-item {
  font-size: 0.95em !important;
  line-height: 1.5em !important;
  font-weight: lighter !important;
}

.disclaimer h2 {
  display: inline;
}

.disclaimer.inline {
  display: inline;
}

.disclaimer_subscript {
  vertical-align: top;
  display: inline-block;
  padding: 4px;
  background-color: #2e2e2e;
  color: #eeeeee;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.5em;
  font-weight: 600;
  width: 20px;
  height: 20px;
}

.disclaimer ol {
  padding-left: 15px;
}

.disclaimer ol li {
  padding-left: 15px;
  margin-bottom: 15px;
}

/********************
SEARCH STYLES
********************/
.search {
  padding: 125px 0 75px 0;
}

app-searchbox .md-form label {
  z-index: -1;
}

/********************
SUPPORT STYLES
********************/
.support .solid-hero {
  padding: 115px 0 40px 0;
  background: var(--grey-darkest);
}

.support .solid-hero h1 {
  text-align: center;
  color: white !important;
}

.support .md-form.md-outline input[type="text"] {
  background: white;
  border: none;
  padding: 12px;
  color: var(--grey-darkest);
  font-size: 1.2em;
}

.support label {
  padding: 5px;
  padding-left: 16px !important;
  color: var(--grey-darkest) !important;
}

.support .md-form.md-outline label.active {
  transform: translateY(-36px) scale(0.8) !important;
  background: none !important;
  color: var(--grey-lite) !important;
  left: -10px !important;
}

.support .md-form.md-outline label.active mdb-icon,
.support .md-form.md-outline label.active mdb-icon .active {
  color: var(--grey-lite) !important;
}

.support mdb-card-footer {
  background: none;
  border: none;
  padding-top: 0;
  padding-bottom: 40px;
}

.support mdb-icon {
  color: var(--secondary-color-dark);
}

/********************
SHOPPING CART & CHECKOUT STYLES
********************/

// Cart Payment

.cart-payment {
  .downpayment-disclaimer {
    padding-top: 10px;
    font-size: 0.8rem;
    line-height: 18px;
    color: #747373;

    &.pad-disclaimer {
      padding-top: 40px;
    }
  }

  .card-body {
    padding: 0px;
  }

  .card-payments,
  .card,
  .tab-content {
    box-shadow: none;
    padding: 0rem;
  }

  .nav.md-tabs-payments {
    box-shadow: none;
    background-color: #efefef;
    padding: 8px;
    border-radius: 50px;
    margin-bottom: 15px;

    .nav-item {
      width: 50%;
      color: #999da3;

      a {
        text-align: center;

        span {
          white-space: nowrap;
        }
      }

    }

    .shopping-cart a {
      text-align: center;
    }

    .nav-item.active {
      background-color: #ffffff;
      border-radius: 25px;
      border-color: #a7a7a7;
      border-style: solid;
      border-width: 1px;
    }
  }
}

//Payment Options

.remaining-payments {
  font-size: 18px;
  padding: 0px;
}

.total-today {
  font-size: 14px;
  font-weight: bold;
}

.tax-disclaimer {
  font-size: 12px;
  color: #747373;
  line-height: 18px;
}

h4.coverage-starts {
  font-size: var(--h4-size) !important;
  font-weight: var(--h4-weight) !important;
  line-height: var(--h4-lineheight) !important;
  color: var(--primary-color-dark) !important;
}

.cart-items-mobile {
  .media {
    width: 100%;
  }
}

.where-registered {
  color: #041022;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
}

.checkout-products {
  .media-body {
    border: 1px solid #cdcdcd;
  }

  a.click-text {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    color: var(--color-info);
    text-align: right;
  }
}

a.click-text {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  color: var(--color-info) !important;
}

hr.payment-summary-border {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.shopping-cart {
  padding: 100px 10px 30px 10px;

  .cart-empty {
    height: 400px;


    .cart-empty-icon {
      margin-bottom: 20px;
    }

    .cart-empty-title {
      color: #041022;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 32px;
      margin-bottom: 5px;
      text-align: center;
    }

    .cart-empty-body {
      margin-bottom: 30px;
      color: #041022;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
  }

  .pt-8 {
    padding: 8px;
  }

  .pb-8 {
    padding: 8px;
  }

  .pl-15 {
    padding: 15px;
  }

  a {
    color: rgb(56, 56, 56);
    transition: color 300ms ease-in-out;
  }

  a:hover {
    color: rgb(187, 66, 54);
  }

  hr.fade-line {
    background: linear-gradient(to right, rgb(133, 133, 133) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%) left bottom transparent no-repeat;
    height: 1px;
    margin: 5px 0px 5px 0px;
    border: none;
  }

  .width-100 {
    width: 100px;
  }

  .width-75 {
    width: 75px;
  }

  // Cart Items

  .card-header {
    background: transparent;
    padding-left: 0px;

    background: linear-gradient(to left, rgb(133, 133, 133) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%) left bottom transparent no-repeat;
    background-size: 100% 1px;
    border: none;

    padding-top: 8px;
    padding-bottom: 8px;
  }

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
  }

  .total-description {
    font-size: 0.9rem;
  }

  .cart-items {
    position: relative;
  }

  .cart-item-header {
    font-weight: 500;
  }

  .your-vehicle {
    img {
      max-width: 225px;
      max-height: 100px;
    }
  }

  .cart-item-body {
    padding-left: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .description {
      font-size: 15px;
    }
  }

  .flex-item-bottom {
    align-self: flex-stretch;
  }

  .image-parent {
    max-width: 70px;
  }

  .image-100 {
    max-width: 100%;
    clip-path: inset(0 0 20px 0);
  }

  .cart-item-img {
    display: inline;
    max-width: 70px;
  }

  .sub-item {
    position: relative;
    padding: 15px 15px;
    margin-left: 10px;
  }

  .sub-item-border:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0%;
    height: 50%;
    border-left: 1px solid rgb(133, 133, 133);
  }

  .dash-line {
    position: absolute;
    border-bottom: 1px solid rgb(133, 133, 133);
    top: -50%;
    left: 0px;
    width: 15px;
    height: 100%;
  }

  .md-form>input[type="time"]:not(.browser-default)+label,
  .md-form>input[type]:-webkit-autofill:not(.browser-default):not([type="search"])+label {
    text-align: left;
  }

  // Bought Item Section

  .img-container {
    position: relative;
    display: inline-block;
    width: 150px;
  }

  .img-container img {
    width: 100%;
  }

  .img-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: background 500ms ease-in-out;
  }

  .img-container:hover .overlay {
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }

  .overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .payment-options {
    img {
      max-width: 40px;
      margin-right: 8px;
      height: 25px;
    }
  }

  .cvc {
    img {
      width: 30px;
    }

    .input-group-append {
      margin-left: -50px;
    }
  }

  // Checkout Card

  .total-today {
    font-size: 1.3rem;
  }

  .total-today-note {
    font-size: 0.8rem;
  }

  .checkout-note {
    font-size: 0.8rem;
  }

  a.terms {
    font-size: 0.8rem;
    color: rgb(56, 56, 56);
    text-decoration: underline;
  }

  a.go-back {
    font-size: 0.8rem;
    color: rgb(56, 56, 56);
    text-decoration: underline;
  }

  .tabbar {
    margin-bottom: 30px;

    a {
      font-size: 16px;
      color: rgb(97, 97, 97);
      padding: 4px 12px;
    }

    a.active {
      color: rgb(0, 0, 0);
      font-weight: 500;
    }

    a:hover {
      color: var(--primary-color-dark);
    }
  }

  .progress-bar {
    background-color: var(--primary-color-darker);
  }

  .review-item {
    // margin-bottom: 15px;
    margin-top: 15px;
    padding: 0px !important;
  }

  .step {
    margin-right: 10px;
    border-right: 3px solid var(--grey-med);
    padding-right: 18px;
  }
}

.read-terms {
  width: 100%;
  height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #d3d3d3;
  // margin-bottom: 30px;
  padding: 20px;
}

// Confirmation page
.confirmation {
  padding: 100px 10px 30px 10px;
  min-height: 100vh;

  img {
    max-width: 400px;
    width: 100%;
  }
}

// Documents
.documents {
  padding: 100px 10px 30px 10px;
}

.upload-table td {
  word-break: break-all;
}

.documents_table {
  th {
    color: var(--primary-color-darkest);
    border-bottom: 1px solid #999999;

    &:nth-child(2) {
      text-align: right;
    }
  }

  td {
    word-break: break-all;

    &:nth-child(2) {
      text-align: right;
    }
  }

  .document-link {
    cursor: pointer;
  }

  tr:nth-child(even) {
    background-color: #efefef;
  }
}

.document_frame {
  position: relative;
  top: 0;
  left: 0;
  // z-index: 1000000;
  width: 100%;
  height: 100%;
  background-color: white;

  iframe {
    margin-left: 5%;
    position: relative;
    margin-top: 3vh;
    height: 80vh;
    width: 90%;
    border: none !important;
    box-shadow: 5px 5px 10px grey;
  }

  img {
    margin-left: 5%;
    position: relative;
    margin-top: 3vh;
    width: 90%;
    box-shadow: 5px 5px 10px grey;
  }

  .document_controls {
    // padding-top: 40px;
    margin-left: 5%;
    margin-right: 5%;

    .col-3 {
      display: flex;
      align-items: center;
    }
  }
}

/********
VERIFY VEHICLE
************/

.image-border {
  border-color: var(--primary-color-dark) !important;
  border-style: solid;
  border-width: 1px;
}

.vehicle_edit_btn {
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: white;
  background-color: var(--primary-color-dark);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: visible;

  mdb-icon {
    font-size: 0.75rem;
    text-align: center;
  }
}

.vehicle_edit_btn:hover,
.vehicle_edit_btn:active {
  background-color: var(--primary-color-lite);
  cursor: pointer;
}

.vehicle-component {
  .vehicle-information {
    line-height: 1.2;
    font-size: 24px !important;
    text-transform: uppercase;
    font-weight: bold;
  }

  @include breakpoint(xs) {
    // margin-left: 15px;
    margin-bottom: 30px;
    text-align: center !important;
  }
}

/********************
VERIFY ACCOUNT STYLES
********************/
.calendar-legend {
  margin-top: 10px;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid black;
}

.border-line {
  border-bottom: 1px solid #dadada !important;
  margin: 0 1rem;
}

.verify-account {
  margin-top: 125px;
  margin-bottom: 100px;

  @include breakpoint(xs) {
    margin-top: 75px;
  }

  .card {
    @include breakpoint(xs) {
      @include shadow(none);
    }
  }
}

.verify-account .md-tabs {
  background: var(--secondary-color-med);
}

.verify-account .tab-content {
  @include shadow(none);
}

.verify-account .md-pills .nav-link {
  @include shadow(var(--shadow-sm));
  @include radius(5px);
}

.verify-account .md-pills .nav-link.active {
  background-color: var(--secondary-color-dark);
}

.payment-due {
  color: var(--primary-color-dark);
  font-size: 2em;
  font-weight: 500;
  display: inline-block;
  margin-top: 10px;
}

.account-container {
  max-width: 100%;

  &.container-margin {
    margin-bottom: 0;
    margin-top: 60px !important;
    // @media (max-width: 768px) {
    //     margin-top: 90px !important;
    // }
  }

  .customer-info {
    white-space: nowrap;

    .slashes {
      padding: 0 5px 5px 5px;
      color: #dadada;
    }

    span {
      padding: 0 8px;
      white-space: normal;
    }
  }

  .customer-view-coverage {
    font-size: 12px;
    margin-top: 5px;
    padding: 5px 10px;
    color: var(--grey-dark);
    border-top: 1px solid #dadada;
  }

  .plans-tab-section {
    background-color: white;
    width: 100%;
    margin: auto;
  }

  .tabs {
    display: flex;
    flex-flow: row;
    justify-content: center;
    position: relative;
  }

  .vehicle-tab {
    width: 100%;
    background-color: white;
    position: relative;
    // box-shadow: 0 -4px 6px rgb(0 0 0 / 0.15);
    box-shadow: 0 2px 10px rgb(0 0 0 / 0.25);
    cursor: pointer;

    &.active {
      z-index: 2;
    }

    .tab-end-cap {
      background-color: white;
      position: absolute;
      bottom: -10px;
      width: 100%;
      height: 12px;
    }

    .tab-top {
      width: 100%;
      background-color: #f1f1f1;
      padding: 6px 10px;
      border-bottom: 1px solid #dddddd;

      .tab-title {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        .short-name {
          color: var(--primary-color-dark);
        }
      }

      .tab-title-info {
        margin-top: -5px;
        font-size: 13px;
        font-weight: 400;
        color: #888888;
      }
    }

    .tab-bottom {
      padding: 6px 10px;
      font-size: 13px;
      color: #888888;
      text-transform: uppercase;

      .tab-vehicle-info {
        letter-spacing: 0.75px;
        font-weight: bold;
      }

      .tab-vehicle-vin {
        margin-top: -5px;
      }
    }
  }

  .mobile-tabs {
    .mdb-accordion-indicator {
      display: none;
    }

    .accordion.md-accordion {
      padding: 15px;

      .card-header {
        padding: 0;
      }

      .card {
        border-bottom: none !important;

        .card-body {
          color: black;
        }
      }
    }

    .vehicle-tab {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }

  .vehicle-data {
    max-width: 1250px;
    margin: auto;

    .close-section {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 20px;
      top: 5px;
      color: var(--color-info);
      cursor: pointer;
      z-index: 5;
    }
  }

  .vehicle-info-section {
    background-color: white;
    font-weight: 400;
    z-index: 1;
    border-top: 1px solid #ababab;
    position: relative;
    margin-top: 1px;
    display: flex;
    flex-flow: row;
    justify-content: center;


    .plan-section {
      // width: 33%;
      max-width: 400px;
      padding-right: 0 !important;
      margin-right: 0 !important;

      .vehicle-image {
        display: inline-block;
        width: 125px;
        height: 90px;
        margin: -20px 0 -20px -20px;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .coverage-details {
        // border-top: 1px solid #cccccc;
        margin-right: 15px;
        font-size: 15px;
        gap: 1rem;

        strong {
          font-size: 15px;
          text-transform: uppercase;
        }

        .terms {
          color: var(--primary-color-dark);
        }
      }
    }

    .coverage-title {
      position: relative;
      top: -5px;
      font-size: 14px;
      display: inline-block;
      color: #888888;
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
      width: 78%;


      .vin {
        position: relative;
        top: -5px;
        font-weight: bold;

      }
    }

    .coverage-title {
      position: relative;
      top: -5px;
      font-size: 14px;
      display: inline-block;
      color: #888888;
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
      width: 78%;


      .vin {
        position: relative;
        top: -5px;
        font-weight: bold;
      }
    }

    .payment-schedule-section {
      width: 33%;
      max-width: 400px;
      position: relative;
      padding-top: 20px !important;
      border-left: 1px solid #dddddd;
      padding-left: 20px !important;

      .progress {
        border-radius: 10px;
        position: relative;
      }

      .progress-bar {
        background-color: var(--grey-darkest);
        position: relative;
      }

      .payment-circle {
        // display: none !important;
        background-color: white;
        display: block;
        width: 40px;
        height: 40px;
        top: 8px;
        margin-left: -20px;
        border-radius: 25px;
        position: absolute;
        border: 1px solid var(--grey-darkest);
        transition: left 0.6s ease;
      }

      .remaining-balance {
        margin-top: 20px;
        color: #888888;
        font-size: 13px;

        .estimate {
          font-weight: bold;
        }
      }

      .payment-details {
        margin-top: 10px;
        font-size: 15px;

        .detail {
          // padding: 5px 0;
        }

        strong {
          font-size: 15px;
          text-transform: uppercase;
        }

        .payment-due-detail {
          color: var(--primary-color-dark);
          font-weight: bold;
        }

        .calendar div {
          padding: 0;
          color: #888888;
        }

        .black-square {
          color: var(--grey-darkest);
        }
      }
    }

    .payment-method-section {
      width: 33%;
      max-width: 400px;
      border-left: 1px solid #dddddd;

      border: 4px solid red strong {
        font-size: 15px;
        text-transform: uppercase;
      }

      .payment-method {
        margin-top: 10px;
        color: #888888;
        font-size: 13px;
        line-height: 13px;
      }

      .credit-card {
        cursor: pointer;
        padding: 3px;
        // border-radius: 5px;
        // border: 1px solid #cccccc;
        // background-color: #efefef;
        // display: inline-block;
        display: flex;
        width: 50%;
        white-space: nowrap;

        .credit-card-icon {
          // border-radius: 5px;
          border: 1px solid #cccccc;
          background-color: #efefef;
          padding: 0.3rem;
          color: #666666;
        }


        .cc-name {
          font-size: 14px;
          display: inline-block;
          position: relative;
          padding-left: 10px;
          padding-right: 10px;
          color: #888888;
          font-weight: bold;
          letter-spacing: 0.75px;
          padding-bottom: 3px;
          border: 0.5px solid #cccccc;
          width: 100%;
          padding: 7px;
        }
      }

      .billing-address {
        border-top: 1px solid #cccccc;

        .billing-address-header {
          text-transform: uppercase;
          color: #888888;
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 0.75px;
        }

        strong {
          color: #888888;
        }
      }

      .update-disclaimer {
        padding: 10px;
        color: #C3002F;
        background-color: #fae8ed;
      }
    }
  }

  .cc-update-section {
    label {
      font-weight: bold;
    }

    .row {
      margin-left: -15px;
      margin-right: 0;
    }

    .md-form {
      margin-top: 20px;
      margin-bottom: 20px;

      label {
        padding-left: 0;
        width: 120%;
        max-width: 120%;
      }
    }

    .pr5px {
      padding-right: 5px;
    }

    .pl5px {
      padding-left: 5px;
    }

    .error-message {
      margin-top: -3px;
    }
  }

  .mobile .cc-update-section {
    .row {
      margin-left: 0;
    }
  }

  .mobile .vehicle-tab {

    .close-icon-section {
      display: flex;
      justify-content: end;
    }

    .vehicle-info-section {

      border-top: none;
      display: flex;
      flex-flow: column;
      justify-content: center;

      .coverage-title {
        border-bottom: 1px solid #dddddd;


      }


      .plan-section {
        border-right: none;
        width: 100%;
        max-width: 100%;

      }


      .payment-schedule-section {
        border-top: 1px solid #dddddd;
        width: 100%;
        max-width: 100%;
        border-left: none;
        padding-left: 0 0.25rem !important;
      }


    }

    .payment-method-section {
      padding-top: 15px !important;
      border-top: 1px solid #dddddd;
      padding-left: 0 !important;
      border-left: none;
      width: 100%;
      max-width: 100%;

      .credit-card {
        cursor: pointer;
        padding: 3px;
        display: flex;
        width: 50%;
        white-space: nowrap;

        .credit-card-icon {
          border: 1px solid #cccccc;
          background-color: #efefef;
          padding: 0.3rem;
          color: #666666;
        }

        .cc-name {
          font-size: 14px;
          display: inline-block;
          position: relative;
          padding-left: 10px;
          padding-right: 10px;
          color: #888888;
          font-weight: bold;
          letter-spacing: 0.75px;
          padding-bottom: 3px;
          border: 0.5px solid #cccccc;
          width: 100%;
          padding: 7px;
        }
      }
    }
  }

  .mobile .payment-section {
    width: 100%;
    max-width: 100%;
    border-top: none;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .billing-address {
      width: 100%;
      max-width: 100%;
      border-top: 1px solid #dddddd;


      .payment-plan-agreemnt-button {

        width: 100%;
        max-width: 100%;
      }
    }

  }
}

.verify-vehicle .image-cropper {
  background-image: url('/assets/img/vehicle-slug.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;

  &.hide-slug {
    background-image: none !important;
  }
}

.verify-vehicle.opt-in {
  .vehicle-info {
    justify-content: center;
    display: flex;

    .vehicle_edit_btn {
      position: relative;
      bottom: 20px;
      left: 50px;
    }
  }

  .contact-me-btn {
    background: transparent !important;
    border: 1px solid var(--grey-dark) !important;
    color: var(--grey-dark) !important;

    &:hover {
      background: var(--grey-dark) !important;
      color: white !important;
    }
  }

  .plan-title {
    border-bottom: 1px solid #999999;
  }

  .top-border {
    border-top: 1px solid #999999;
  }

  .form-check-label {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
  }

  .shorten-form {
    margin-bottom: 25px !important;

    .error-message {
      top: -2px !important;
    }
  }
}

@include breakpoint(xs) {
  .vehicle-info-container {
    justify-content: center;
  }
}

/********************
FAQ STYLES
********************/
.faq .tab-content {
  @include shadow(none);
  padding: 1rem;
}

.faq .accordion .card {
  @include shadow(var(--shadow-sm));
  border: 1px solid var(--grey);
  margin-bottom: 20px;
}

.faq .accordion .card .card-header {
  color: var(--grey-darker);
  padding: 1.5rem;
}

.faq .accordion .card .card-header h5,
.faq .accordion .card .card-header>a>span {
  margin: 0 !important;
  font-weight: var(--font-medium) !important;
  color: var(--grey-darkest) !important;

  h6 {
    font-weight: bold !important;
  }
}

.faq .md-pills li {
  padding: 1rem;
}

.faq .md-pills .nav-link {
  font-style: italic;
  color: var(--primary-color-dark);
}

.faq .pills-secondary .nav-link.active {
  background: var(--primary-color-dark) !important;
}

.faq .mdb-accordion-indicator {
  top: 20px;
  right: 4px;
}

.faq .mdb-accordion-indicator::after {
  width: 16px;
  height: 16px;
  color: var(--primary-color-dark);
}

.faq .card .card-body {
  padding: 1.5rem;
}

.faq .accordion .card .card-body {
  padding-top: 1rem;
  border-top: 1px solid var(--grey);

  p {
    padding-bottom: 20px;
  }
}

/********************
STEPPER STYLES
********************/
ul.stepper.horizontal {
  height: 600px !important;
}

ul.stepper.horizontal .step-actions,
ul.stepper.horizontal .step-new-content {
  @include breakpoint(xs) {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
}

ul.stepper.horizontal .step-title {
  @include breakpoint(xs) {
    font-size: 0.7em;
  }
}

.step-title {
  pointer-events: none !important;
}

/********************
FORM FIELD & VALIDATION STYLES
********************/
.error-message {
  color: rgb(248, 132, 0) !important;
  top: 45px !important;
}

.no-OID-form {
  .md-form {
    margin-bottom: 45px !important;
  }

  .error-message {
    position: relative !important;
    top: -40px !important;
    display: block;
    width: 100%;
    text-align: left;
    height: 0;
  }
}

.OID-form {
  .md-form {
    margin-bottom: 45px !important;
  }

  .error-message {
    position: relative !important;
    top: -40px !important;
    display: block;
    width: 100%;
    text-align: left;
    height: 0;
  }
}

input.form-control.validate-error.ng-invalid.ng-touched,
input.form-control.validate-error.ng-invalid.ng-dirty,
.form-submitted input.form-control.validate-error.ng-invalid {
  border-bottom: 1px solid rgb(248, 132, 0) !important;
}

input.form-control.validate-error.ng-invalid.ng-touched+label,
input.form-control.validate-error.ng-invalid.ng-dirty+label,
.form-submitted input.form-control.validate-error.ng-invalid+label {
  color: rgb(248, 132, 0) !important;
}

input.form-control.validate-success.ng-valid.ng-dirty+label,
input.form-control.validate-success.ng-valid.ng-touched+label,
.form-submitted input.form-control.validate-success.ng-valid+label {
  color: rgb(20, 185, 34) !important;
}

input.form-control.validate-success.ng-valid.ng-dirty,
input.form-control.validate-success.ng-valid.ng-touched,
.form-submitted input.form-control.validate-success.ng-valid {
  border-bottom: 1px solid rgb(20, 185, 34) !important;
}

.mdb-select-toggle {
  margin-top: -0.25rem !important;
  font-size: 12px !important;
}

mdb-select>div>div.single>div.placeholder {
  color: #757575 !important;
  font-weight: 300 !important;
}

// APP COMPONENT CTA

app-component-cta .btn {
  @include breakpoint(xs) {
    width: 100%;
  }
}

.cta-icon-wrap {
  width: 50%;
  float: left;

  @include breakpoint(xs) {
    width: 100%;
  }
}

app-component-icon-group {
  margin-bottom: 1rem;
  display: block;
}

/*****************************
Prismic Label Styles
*****************************/
.superscript {
  font-weight: 100 !important;
  vertical-align: super;
  line-height: 0;
  padding-left: 2px;
  padding-right: 2px;
}

.italics {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.subscript {
  vertical-align: sub;
}

.covered-parts {
  height: 200px;

  @include breakpoint(xs) {
    height: 260px;
  }
}

.covered-part {
  min-height: 30px;
  width: 250px;

  @include breakpoint(xs) {
    min-height: 25px;
    width: 53%;
  }
}

.cap-head .superscript {
  font-size: 0.8em !important;
}

h2 .superscript {
  font-size: 0.4em !important;
}

p .superscript,
li .superscript,
ol .superscript {
  font-size: 0.7em !important;
}

.gray {
  color: #8C8C8C;
}

/*********
* Legal
***********/

.legal {
  // padding-left: 1.5rem;
  // padding-right: 1.5rem;
  // padding-bottom: 1.5rem;
  padding-top: 100px !important;
  padding-bottom: 80px !important;
}

/*****
 * Browser warning modal
 ******/

.browser-img {
  max-width: 20%;
  height: auto;
}

.browser-warning {
  margin-top: 120px;
}

.browser-warning-modal {
  .modal-close-icon {
    top: 10px;
    left: 10px;
  }

  .modal-lg,
  .modal-xl {
    @include breakpoint(lg) {
      max-width: 700px !important;
    }
  }
}

.modal-timeout {
  .primary-btn {
    max-width: 300px;
  }
}

.modal-content.disclaimer {
  @include breakpoint(xs) {
    .col-sm-1.offset-md-1.col-md-10.d-flex {
      max-width: 100%;
    }
  }
}

.modal-content .row {
  flex-direction: column;

  .btn {
    width: 100% !important;
    max-width: 320px;
  }
}

.select-container {
  position: relative;
  margin-top: -48px;
  background-color: white;

  .container {
    text-align: center;
    min-width: 100%;
  }

  .price-plan,
  .price-plan-one-time {
    min-width: 25%;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;

    .price-priceHeader {
      padding-right: 0;
      padding-left: 0;
      padding-top: 14px;
      flex: 0 0 110px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
      font-weight: normal;
      line-height: 24px;
      color: #444444;

      .price-cost {
        color: black;
        font-size: var(--h2-size);

        .price-cost-asterisk {
          font-size: 25px;
          vertical-align: super;
        }
      }
    }

    .price-details {
      padding-top: 14px;
      margin-top: 10px;
      font-size: 13px;
      flex: 1;
    }
  }

  .excludes-tax {
    font-size: 12px;
    color: var(--grey-darkest);
    line-height: 18px;
    padding-bottom: 10px;
    padding-top: 5px;

    strong {
      color: black;
      font-weight: 600;
    }
  }

  .deductible-line {
    border-top: 1px solid var(--grey-dark);
  }

  .deductible-btn {
    position: relative;
    padding: 5px 0 !important;
    width: 85px;
    font-size: 13px !important;

    &.active {
      background-color: black;
    }
  }

  &.subscription .deductible-btn {
    position: relative;
    border-radius: 0 !important;
    padding: 5px 0 !important;
    width: 85px;
    font-size: 13px !important;
    background-color: white !important;
    color: black !important;
    border-width: 1px !important;
    font-weight: 200;

    &.active {
      background-color: black !important;
      color: white !important;
      border-color: black !important;
    }
  }





  .price-plan-one-time {
    min-width: 25%;
    width: 100%;
    flex-direction: row;

    .price-priceHeader {
      padding-right: 0;
      padding-left: 0;
      padding-top: 14px;
      flex: 0 0 110px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
      font-weight: bold;
      line-height: 24px;
      color: #444444;

      .price-cost {
        color: black;
        font-size: 24px;

        .price-cost-asterisk {
          font-size: 20px;
          vertical-align: super;
        }
      }
    }

    .price-details {
      padding-top: 14px;
      margin-top: 10px;
      font-size: 13px;
      flex: 1;

      .excludes-tax {
        font-size: 11px;
      }
    }
  }

  .disappearing_def {
    font-size: 12px;
    color: #777777;
    line-height: 18px;
    padding-bottom: 10px;
    padding-top: 5px;
  }

  @include breakpoint(xs) {
    .mobile-price-plan {

      .col-8,
      .col-12 {
        >div {
          float: left;
          padding-top: 10px;
          padding-left: 5px;
          text-align: left;
          font-size: 13px;
          // font-weight: bold;
          color: black;
          // span {
          //     font-size: 12px;
          //     color: #777777;
          // }
        }
      }

      .col-12 {
        >div {
          padding-top: 0px;
          padding-bottom: 5px;
          text-align: center;
          width: 100%;

        }
      }

      .price {
        .price-cost {
          font-size: 32px;
          line-height: 30px;
          padding-top: 36px;
        }

        .price-cost-asterisk {
          font-size: 20px;
          vertical-align: super;
        }
      }
    }
  }

  .plan-select-border {
    min-width: 25%;
    width: 100%;
    max-width: 320px;
  }

  .plan-select-border {
    border-top: 3px solid var(--grey-darkest);
  }

  .plan-select-array {
    margin-top: 3px;
  }

  .plan-select {
    color: white;
    padding: 5px;
    user-select: none;
    cursor: pointer;
    min-width: 25%;
    width: 100%;
    max-width: 320px;
    margin-top: 0;
    display: inline-block;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    background-color: var(--primary-color-darker);
    border-left: 3px solid white;
    border-bottom: 1px solid white;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    // transition: height 0.125s linear, margin 0.125s linear;
    // transition: height .2s linear;
    &.active {
      margin-top: -3px;
      position: relative;
      text-align: center;
      color: white !important;
      border-bottom: var(--grey-darkest);
      background-color: var(--grey-darkest);
    }

    &:first-child {
      border-left: none !important;
    }

    .plan-header {
      position: relative;
      margin-top: -14px;
      text-transform: uppercase;
      color: black;
      font-weight: bold;
      font-size: 14px;
      width: 100%;
      text-align: center;
    }
  }
}

.pretext {
  overflow: visible;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  background: transparent !important;
}

:root #gridTable,
:root .grid-table {
  white-space: normal;
  margin: 10px;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);

  th,
  td {
    padding: 6px 6px;
    border-right: 1px solid #e2e2e2;
    font-size: 14px;
    text-align: center;
  }

  td:nth-child(1) {
    font-weight: bold;
    color: #555555;
    text-align: left;
  }

  td:nth-child(3) {
    font-weight: bold;
  }

  th {
    background-color: #ffffff;
    text-transform: none !important;
    color: #555555;
  }

  th:nth-child(1) {
    color: black;
    font-size: 16px;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:nth-child(odd) {
    background-color: #ffffff;
  }
}

:root .grid-table-plain {
  white-space: normal;
  margin: 10px;
  border: 1px solid #e2e2e2;

  th,
  td {
    padding: 10px 6px;
    border-right: 1px solid #e2e2e2;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #e2e2e2;
  }

  td:nth-child(1) {
    font-weight: bold;
    color: #555555;
    text-align: left;
  }

  td:nth-child(3) {
    font-weight: bold;
  }

  th {
    background-color: #ffffff;
    text-transform: none !important;
    color: #555555;
  }

  th:nth-child(1) {
    color: black;
    font-size: 16px;
    text-align: left;
  }
}

:root .cookie-acceptance-modal {
  top: auto;
  right: auto;
  left: 0;
  bottom: 0;
  position: fixed;
  opacity: .95;
  color: white;
  max-height: 150px;
}

:root .cookie-acceptance-modal-button {
  width: auto;
}

:root p.cookie-acceptance-modal-text {
  font-size: 11px !important;
}

:root .choose-term {
  max-width: 350px;
  float: right;
}

:root .confirmation {
  h1 {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 750px) {
  :root .choose-term {
    max-width: 100%;
    float: left;
  }
}

.md-form.terms-select {
  margin-top: 5px;
  max-width: 320px;
}

.generic-term-length {
  text-align: left;

  @include breakpoint(xs) {
    text-align: center;
  }
}

app-modal {
  .view {
    background-image: url('/assets/img/vehicle-slug.png');

    @media (max-width: 600px) {
      background-size: 60%;
    }
  }
}

.no-payment-options li {
  width: 100% !important;
}

.one-time-payment-font-size {
  font-size: 32px !important;
}

.normal-font-weight {
  font-weight: normal !important;
}

.whats_this_panel {
  margin: 5px 0;

  div {
    color: #606060;
    font-size: 14px;
    line-height: 18px;
  }
}

/****************
* Okta Widget
*****************/

.update-password-banner {
  background-color: #E8D6D6;
  color: #C43F3F;
  padding: 5px 0;
  font-size: 12px;
  font-weight: bold;
}

#widget-container,
#password-reset-container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 30px;
  text-align: center;
  min-height: 250px;

  .o-form-fieldset {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .email-button {
    margin-top: 20px;
  }

  .okta-spinner {
    position: absolute;
    top: 100px;
    left: -25px;
  }

  .js-help {
    display: none;
  }

  .disclaimer {
    padding-top: 5px;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: 13px;
    color: #999999;
    line-height: 18px;
  }

  .error-message {
    position: relative;
    top: 0 !important;
    display: block;
  }
}

.o-form-fieldset-container {
  max-width: 320px;
  margin: 0 auto;
}

.o-form-button-bar {
  margin-top: 20px;
}

.okta-form-title {
  text-align: center;
}

.identifier-container {
  display: none;
}

.o-form-button-bar .button-primary,
.enter-auth-code-instead-link {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  transition: all .2s ease-in-out;
  margin: 5px 0 !important;
  width: 320px;
  max-width: 100%;
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: none;
  font-weight: 300;
  font-size: 20px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 10px 0;
  line-height: 25px;

  &:hover {}
}

.enter-auth-code-instead-link {
  width: auto;
  padding: 10px 10px;
}

.okta-form-input-field input,
.chzn-search input {
  border-bottom: none !important;
  width: 310px;
  outline: none;
  // box-shadow: 0 0 3px rgb(0 0 0 / 50%);
  border-radius: 0;
  box-sizing: content-box;
  background-color: transparent;
  border: none !important;
  font-size: 1rem;
  padding: 8px 5px;
  font-weight: 600;
  line-height: 25px;
}

span.o-form-control {
  display: block;
  box-shadow: 0 0 3px rgb(0 0 0 / 50%);
}

ol.qrcode-info {
  text-align: left;
  max-width: 700px;
}

.okta-form-label {
  max-height: 25px;
  text-align: left;

  label {
    font-weight: 600;
    transition: transform 0.2s ease-out, color 0.2s ease-out, max-width 0.2s ease-out;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    top: 0;
    left: 0;
    font-size: 0.85rem;
    cursor: text;
    margin-bottom: 0;
  }
}

.o-form-input-name-rememberMe {
  label {
    cursor: pointer;
    display: inline-block;
    height: 1.5625rem;
    line-height: 1.5625rem;
    padding-left: 25px;
    position: relative;
    user-select: none;

    &::before {
      border-radius: 1px;
      content: "";
      height: 18px;
      left: 0;
      margin-top: 3px;
      position: absolute;
      top: 0;
      transition: .2s;
      width: 18px;
      z-index: 0;
    }

    &::after {
      border-radius: 1px;
      content: "";
      height: 18px;
      left: 0;
      margin-top: 3px;
      position: absolute;
      top: 0;
      transition: .2s;
      width: 18px;
      z-index: 0;
    }
  }

  input {
    opacity: 0;
    pointer-events: none;
    position: absolute;

    &:checked {
      &+label:before {
        border-radius: 1px;
        content: "";
        margin-top: 3px;
        position: absolute;
        transition: .2s;
        z-index: 0;
        backface-visibility: hidden;
        border-style: solid;
        border-width: 2px;
        height: 1.375rem;
        left: -5px;
        top: -4px;
        transform: rotate(40deg);
        transform-origin: 100% 100%;
        width: 12px;
      }

      &+label:after {
        border: 0;
        transform: scale(0);
        border-radius: 1px;
        content: "";
        height: 18px;
        left: 0;
        margin-top: 3px;
        position: absolute;
        top: 0;
        transition: .2s;
        width: 18px;
        z-index: 0;
      }
    }
  }
}

.authenticator-list-title {
  font-weight: bold;
}

.o-form-input-name-phoneCode {

  .o-form-input-name-phoneCode span,
  .phone-authenticator-enroll__phone-code {
    width: 40px;
    display: none;
  }

  input {
    // margin-left: 10px;
    // width: 260px;
  }
}

.phone-authenticator-enroll__phone-ext {
  display: none;
}

.okta-form-infobox-error {
  color: var(--color-error) !important;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: none;
}

:root .okta-form-input-error {
  color: var(--color-error) !important;
  font-size: 10px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
  text-transform: none;
  position: relative;
  margin-top: 3px;
  text-align: left;
}

.auth-footer a {
  display: block;
}

a:not([href]):not([tabindex]) {}

.infobox-error .error-16+div {
  display: none;
}

.authenticator-row {
  padding-top: 15px;

  &:last-child {
    padding-top: 30px;

    .authenticator-description {
      margin-bottom: 1rem;
    }
  }
}

.authenticator-enroll-list {
  .authenticator-row {
    &:last-child {
      padding-top: 30px;
    }
  }
}

.okta-form-subtitle {
  padding-top: 10px;
  padding-bottom: 15px;

  p {
    display: inline;
  }
}

.authenticator-enrollment-data--phone_number {
  .o-form-fieldset-container {
    .custom-radio {
      display: inline-block;
      position: relative;
      padding: 0 6px;
      margin: 10px 0 0;

      input[type='radio'] {
        display: none;
      }

      label {
        color: #666;
        font-weight: normal;
        cursor: pointer;
      }

      label:before {
        content: " ";
        display: inline-block;
        position: relative;
        top: 2px;
        margin: 0 5px 0 0;
        width: 15px;
        height: 15px;
        border-radius: 11px;
        background-color: transparent;
        cursor: pointer;
      }

      input[type=radio]:checked+label:after {
        border-radius: 11px;
        width: 7px;
        height: 7px;
        position: absolute;
        top: 10px;
        left: 10px;
        content: " ";
        display: block;
        cursor: pointer;
      }
    }

    .o-form-fieldset:nth-child(2) {
      display: none;
    }
  }
}

.payment-history-paylink {
  color: #888888;
  font-size: 13px;
  line-height: 13px;
}

#languageDropdown {
  position: fixed;
  top: 85px;
  right: 0;
  padding: 0 5px;
  color: black;
  z-index: 1000;
  font-size: 12px;

  @include breakpoint(xs) {
    top: 100px;
  }

  select {
    cursor: pointer;
    height: 24px !important;
    background-color: transparent;
  }

  select:focus {
    padding: 0;
    outline: none;
  }
}

.home-widget-vsp .vehicle-info-container {
  justify-content: center;
  align-items: center;
}

// CookieYes
.cky-category-direct-switch,
.cky-switch {
  input[type='checkbox'] {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
}

.pdf-iframe {
  height: 300px;
  width: 100%;
  overflow: hidden;
  padding: 0px !important;
  border: 1px solid #d3d3d3;
  margin: 10px 0 40px 0;
}

.view-contract.pdf-iframe {
  height: 800px;
  width: 100%;
  overflow: hidden;
  padding: 0px !important;
  margin-top: 60px;
}

app-image-carousel {
  .slick-dotted.slick-slider {
    @media (max-width: 767px) {
      margin-bottom: -30px !important;
    }
  }

  .slick-dots {
    bottom: 30px !important;

    @media (max-width: 767px) {
      bottom: 50px !important;
    }

    li {
      width: 70px;

      button {
        width: 60px;
        height: 3px;
        padding: 0;
        border-radius: 0;
        background: #999999;

        &::before {
          content: "";
        }
      }
    }

    li.slick-active {
      button {
        background-color: #ffffff;
      }
    }
  }
}

.inspection-banner {
  background-color: #eedbdf;
  color: #ce365c;
  display: block;
  width: 100%;
}

.ngx-file-drop__drop-zone {
  border: 1px dashed #777777 !important;
  border-radius: 0 !important;

  .ngx-file-drop__content {
    color: #333333 !important;

    a {
      color: var(--primary-color-darkest) !important;

      &:hover {
        color: var(--primary-color-dark) !important;
      }
    }
  }
}

.ngx-file-drop__content {
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.horizontal-rule {
  border-top: 1px solid #dee2e6;
}

.modal-vehicle-inspection {
  .modal-content {
    border-radius: 10px !important;

    .upload-icon {
      position: absolute;
      top: 12px;
      font-size: 18px;
      color: #999999 !important;

      @media (max-width: 767px) {
        top: 10px;
      }
    }
  }

  .ngx-file-drop__drop-zone,
  .ngx-file-drop__content {
    @media (max-width: 767px) {
      height: 130px !important;
    }
  }
}

.no-shadow {
  box-shadow: none !important;
}

.warranty_btn {
  width: 25%;
  border: 1px solid var(--primary-color-darkest) !important;
  background-color: white;
  padding: 8px 0 !important;

  &:hover {
    background-color: #eeeeee;
  }

  &.active {
    background-color: black;
    color: white !important;
  }
}

// .content-feature.countdown-timer {
//   padding-top: 0 !important;
// }

// .nav-margin {
//   margin-top: 63px;

//   @media only screen and (max-width: 767px) {
//     margin-top: 58px;
//   }
// }

.select-container {
  // background-color: #f3f3f3;
  // overflow: auto;
  margin-top: 0;

  &.switch {
    text-align: center;
    padding-top: 15px;

    label {
      border-top: 1px solid #999999;
      padding-top: 15px;
    }
  }

  .plans-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    // margin-bottom: 40px;
    align-items: flex-start;

    @include breakpoint(xs) {
      flex-direction: column;
      justify-content: center;
      align-items: normal;
    }

    .deductible-bullets {
      ul {
        margin-bottom: 0;
      }
    }

    .price-cost-asterisk {
      vertical-align: super;
      font-size: 15px !important;
    }

    .plan-container {
      position: relative;
      margin: 6px;
      background-color: white;
      border: 5px double #aaaaaa;
      min-width: 275px;
      max-width: 320px;
      width: 320px;
      margin-bottom: 70px;
      cursor: pointer;

      @include breakpoint(xs) {
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 70px;
        max-width: 100%;
      }

      .inner-container {
        background-color: white;
        position: relative;
        height: 100%;
        padding: 15px 5px 35px 5px;
        // margin-bottom: 25px;
        // border: 3px solid white;
      }

      .plan-name {
        color: var(--primary-color-dark);
        font-size: 16px;
        font-weight: 600;
        padding-top: 8px;
      }

      .sub-button-container {
        position: relative;
        height: 0;

        .sub-button {
          margin-top: -30px;
          position: absolute;
          width: 100%;
        }

        @include breakpoint(xs) {
          position: absolute;
          padding-left: calc(50% - 100px);
        }

        .primary-btn {
          color: white !important;
          border-radius: 0 !important;
          width: 75%;
          position: relative;
          background-color: var(--primary-color-dark) !important;
          border: 2px solid var(--primary-color-dark) !important;
          text-transform: none;

          @include breakpoint(xs) {
            min-width: 200px;
          }

          &:hover {
            background-color: var(--primary-color-darkest) !important;
            border: 2px solid var(--primary-color-darkest) !important;
          }
        }

        .secondary-btn {
          border-radius: 0 !important;
          width: 75%;
          text-transform: none;
          position: relative;
          color: white !important;
          background-color: var(--primary-color-dark) !important;
          border: 2px solid var(--primary-color-dark) !important;

          @include breakpoint(xs) {
            min-width: 200px;
          }

          &:hover {
            background-color: var(--primary-color-darkest) !important;
            border: 2px solid var(--primary-color-darkest) !important;
          }
        }
      }

      &.active {
        border: 5px solid #000000;

        .inner-container {
          // border:3px solid #000000;
          // padding: 0 0 0 0;
          // margin-bottom: 10px;
        }

        .sub-button {
          // margin-top: -26px;
        }
      }
    }
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.select-your-plan {
  .slick-dotted.slick-slider {
    margin-bottom: 0 !important;

    @media (max-width: 767px) {
      margin-bottom: -30px !important;
    }
  }

  .slick-dots {
    bottom: 0 !important;

    @media (max-width: 767px) {
      bottom: 0 !important;
    }

    li {
      width: 70px;

      button {
        width: 60px;
        height: 3px;
        padding: 0;
        border-radius: 0;
        background: var(--grey-dark);

        &::before {
          content: "";
        }
      }
    }

    li.slick-active {
      button {
        background-color: var(--primary-color-darkest);
      }
    }
  }

  .slick-prev {
    top: 50px;
    color: var(--primary-color-darkest);

    &::before {
      color: var(--primary-color-darkest);
    }

    @include breakpoint(xs) {}
  }

  .slick-next {
    top: 50px;
    color: var(--primary-color-darkest);

    &::before {
      color: var(--primary-color-darkest);
    }

    @include breakpoint(xs) {}
  }
}

.select-coverage-deductible,
.coverage-terms-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: baseline;

  .estimated-coverage-text,
  .estimated-coverage-terms {
    font-size: 14px;
  }

  .estimated-coverage-terms {
    color: var(--primary-color-darkest);
  }

  label {
    font-size: 13px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

.select-coverage-deductible {
  mat-button-toggle {
    width: 100%;
  }
  .mat-expansion-panel {
    box-shadow: none !important;
  }

  .disappearing_deductible {
    max-width: 225px;
    font-size: 14px;
    line-height: 17px;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--font-family)
  }

  .mat-button-toggle-group-appearance-standard,
  .mat-button-toggle-button {
    border-radius: 0;
    border-color: var(--primary-color-darkest);
    color: var(--primary-color-darkest) !important;
  }

  .mat-button-toggle-checked .mat-button-toggle-button {
    color: white !important;
  }

}

.mat-button-toggle-appearance-standard {
  .mat-button-toggle-label-content {
    line-height: 40px;
  }
}

.mat-button-toggle-checked {
  background-color: var(--grey-darkest);
  color: white !important;
  border-color: var(--grey-darkest);
}

.tooltips {
  background-color: #606060;
  text-align: center !important;
  justify-content: center;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 10px;
  font-family: Arial, sans-serif;

  .mdc-tooltip__surface {
    text-align: center !important;
    justify-content: center;
  }
}

mat-expansion-panel {
  background: transparent !important;
}

app-coverage-options {
  display: block;
  padding-bottom: 40px;

  button {
    min-width: 75px;
  }
}

:root .faq-overview {
  h3 {
    text-transform: none !important;
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
  }

  .mat-expansion-panel-header {
    padding: 0 7px;
    height: unset !important;
    min-height: 48px;
    align-items: flex-start !important;

    .mat-expansion-indicator {
      padding-top: 5px !important;
    }

    .mat-content {
      padding-top: 5px !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .mat-expansion-panel-spacing {
    margin-top: 15px !important
  }

  .mat-expansion-panel {
    margin: 1rem 0;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px solid var(--grey);
  }

  .mat-expansion-panel-body {
    padding-left: 10px !important;
    padding-right: 10px !important;

    p {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }

  .material-symbols-outlined {
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
  }

}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  border-radius: 15px !important
}